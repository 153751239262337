import React from "react";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LoadingBtn from "./LoadingBtn";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ErrorModal from "./ErrorModal";
import { BOOST__DOMAINID } from "../Utils";
import { getPublishVersion } from "./getPublishVersionHelper";

const NewFeaturesForm = styled(Paper)`
  form {
    padding: 0 24px 24px;
  }
`;
const Formbox = styled.div`
  background-color: rgba(0, 0, 0, 0.03);
  padding: 18px;
  margin: 8px 0;
  h5 {
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 -12px;
  }
  h6 {
    font-size: 14px;
    margin: 12px 0 -12px;
  }
`;
const AddButton = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
`;

const FormInputInlineFlex = styled.div`
  display: flex;
  flex: 1;
  margin-left: -8px;
  margin-right: -8px;

  > div {
    margin-left: 8px;
    margin-right: 8px;
  }
`;
const featureTypes = [
  "Advance",
  "Recommendation",
  "Socialproof-Ecount",
  "Socialproof-Recency",
  "Socialproof-Topk",
  "Low-Stock"
];

const socialproofEvents = [
  "View a product",
  "Add a product to cart",
  "Purchase a product"
];

const recommendationTypes = [
  "alsoview",
  "alsobuy",
  "alsolike",
  "maybuy",
  "recently",
  "trending",
  "popular",
  "new"
];

class FeatureFromModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      error: null,
      featureTypes,
      socialproofEvents,
      recommendationTypes,
      featureType: 0,
      socialproofEvent: 0,
      recommendationType: 0,
      submitLoading: false,
      customGoalRequired: false
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCustomGoal = this.handleCustomGoal.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  handleChangeFeatureType(event) {
    this.setState({ featureType: event.target.value });
  }

  handleOpen() {
    this.fetchData();
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleCustomGoal(event) {
    const {
      custom_goal_name,
      custom_goal_simple_size,
      custom_goal_convert_event
    } = this.state;

    if (event.target.value) {
      this.setState({ customGoalRequired: true });
    } else {
      if (
        !custom_goal_name &&
        !custom_goal_simple_size &&
        !custom_goal_convert_event
      ) {
        this.setState({ customGoalRequired: false });
      }
    }
  }

  async handleSubmit(event) {
    const { featureTypes } = this.state;

    this.setState({ submitLoading: true });
    event.preventDefault();
    const eles = event.target.elements;
    let data = {
      setting: {
        control_percent: 100 - parseInt(eles.percent.value, 10),
        conversions_goal: {
          sample_size: parseInt(eles.conversions_goal_simple_size.value, 10)
        },
        revenue_per_visitor_goal: {
          sample_size: parseInt(
            eles.revenue_per_visitor_goal_simple_size.value,
            10
          )
        },
        revenue_per_converter_goal: {
          sample_size: parseInt(
            eles.revenue_per_converter_goal_simple_size.value,
            10
          )
        }
      }
    };

    if (eles.custom_goal_name.value.length !== 0) {
      data.setting.custom_goal = {
        name: eles.custom_goal_name.value,
        sample_size: parseInt(eles.custom_goal_simple_size.value, 10),
        convert_event: eles.custom_goal_convert_event.value
      };
    }

    if (parseInt(eles.type.value, 10) !== 0) {
      const featuresType = (featureTypes[eles.type.value] || "").toLowerCase();
      let social_proof = {};
      if (eles.social_proof_event && eles.social_proof_duration) {
        social_proof = {
          event: parseInt(eles.social_proof_event.value, 10),
          duration: eles.social_proof_duration.value
        };
      }

      switch (featuresType.toLowerCase()) {
        case "socialproof-ecount":
          social_proof["threshold"] = parseInt(
            eles.social_proof_threshold.value,
            10
          );
          data.setting["social_proof_ecount_config"] = social_proof;
          break;
        case "socialproof-recency":
          data.setting["social_proof_recency_config"] = social_proof;
          break;

        case "socialproof-topk":
          data.setting["social_proof_topk_config"] = social_proof;
          break;
        case "recommendation":
          data.setting["recommendation_config"] = {
            type: parseInt(eles.recommendation_config_type.value, 10),
            title: eles.recommendation_config_title.value,
            total_num: parseInt(eles.recommendation_config_total_num.value, 10),
            num_per_page: parseInt(
              eles.recommendation_config_num_per_page.value,
              10
            )
          };
          break;
        case "low-stock":
          const threshold = parseInt(eles.low_stock_threshold.value, 10);
          if (threshold > 0) {
            data.setting["low_stock_config"] = {
              threshold
            };
          }
          break;
        default:
      }
    }

    if (this.props.featureId) {
      data = data.setting;
    } else {
      data = Object.assign({}, data, {
        profile: {
          name: eles.name.value,
          description: eles.description.value
        },
        type: parseInt(eles.type.value, 10)
      });
    }

    try {
      let api = `/api/d/${BOOST__DOMAINID}/features`;
      if (this.props.featureId) {
        api += `/${this.props.featureId}/edit`;

        try {
          const data = {
            description: eles.description.value,
            name: eles.name.value
          };
          await axios.post(
            `/api/d/${BOOST__DOMAINID}/features/${this.props.featureId}/profile `,
            data
          );
        } catch (error) {
          this.setState({
            error: error,
            submitLoading: false
          });
        }
      }

      const result = await axios.post(api, data);
      this.setState({
        open: false,
        submitLoading: false
      });
      if (this.props.featureId) {
        window.location.pathname = `/d/${BOOST__DOMAINID}/features/${this.props.featureId}/draft`;
      } else {
        window.location.pathname = `/d/${BOOST__DOMAINID}/features/${result.data.data}`;
      }
    } catch (error) {
      this.setState({
        error: error,
        submitLoading: false
      });
    }
  }

  async fetchData() {
    if (this.props.featureId) {
      try {
        const featureData = await axios.get(
          `/api/d/${BOOST__DOMAINID}/features/${this.props.featureId}`
        );

        this.setState({
          data: featureData.data.data
        });

        const {
          type,
          latest_published_version,
          waiting_publish_version,
          draft_version,
          state,
          profile
        } = featureData.data.data;

        this.state.description = profile.description;
        this.state.name = profile.name;

        this.state.featureType = type;

        const published_version = getPublishVersion({
          draft_version,
          state,
          latest_published_version,
          waiting_publish_version
        });

        const { variations, goals } = published_version;

        const variation = variations.find(v => v.is_control === false);
        const {
          recommendation_config,
          social_proof_ecount_config,
          social_proof_recency_config,
          social_proof_topk_config,
          low_stock_config
        } = variation;
        this.state.percent = variation.percent;
        const orderdGoals = goals.sort((a, b) => a.type - b.type);

        this.state.conversions_goal_simple_size = orderdGoals[0]
          ? orderdGoals[0].sample_size
          : "";
        this.state.revenue_per_visitor_goal_simple_size = orderdGoals[1]
          ? orderdGoals[1].sample_size
          : "";
        this.state.revenue_per_converter_goal_simple_size = orderdGoals[2]
          ? orderdGoals[2].sample_size
          : "";
        this.state.custom_goal_name = orderdGoals[3] ? orderdGoals[3].name : "";
        this.state.custom_goal_simple_size = orderdGoals[3]
          ? orderdGoals[3].sample_size
          : "";
        this.state.custom_goal_convert_event = orderdGoals[3]
          ? orderdGoals[3].convert_event
          : "";

        const typeConfig = [
          recommendation_config,
          social_proof_ecount_config,
          social_proof_recency_config,
          social_proof_topk_config,
          low_stock_config
        ].find(config => config);
        const featuresType = this.state.featureTypes[type];
        switch (featuresType.toLowerCase()) {
          case "socialproof-ecount":
            this.state.socialproofEvent = typeConfig.event;
            this.state.social_proof_duration = typeConfig.duration;
            this.state.social_proof_threshold = typeConfig.threshold;
            break;
          case "socialproof-recency":
            this.state.socialproofEvent = typeConfig.event;
            this.state.social_proof_duration = typeConfig.duration;
            break;

          case "socialproof-topk":
            this.state.socialproofEvent = typeConfig.event;
            this.state.social_proof_duration = typeConfig.duration;
            break;
          case "recommendation":
            this.state.recommendationType = typeConfig.type;
            this.state.recommendation_config_num_per_page =
              typeConfig.num_per_page;
            this.state.recommendation_config_title = typeConfig.title;
            this.state.recommendation_config_total_num = typeConfig.total_num;
            break;
          case "low-stock":
            this.state.low_stock_threshold = typeConfig.threshold;
            break;
          default:
        }
      } catch (error) {
        this.setState({
          error
        });
      }
    }
  }

  componentWillMount() {
    this.fetchData();
  }

  render() {
    const {
      open,
      submitLoading,
      featureTypes,
      socialproofEvents,
      recommendationTypes,
      featureType,
      socialproofEvent,
      social_proof_duration,
      social_proof_threshold,
      recommendationType,
      recommendation_config_num_per_page,
      recommendation_config_title,
      recommendation_config_total_num,
      percent,
      conversions_goal_simple_size,
      revenue_per_visitor_goal_simple_size,
      revenue_per_converter_goal_simple_size,
      custom_goal_name,
      custom_goal_simple_size,
      custom_goal_convert_event,
      customGoalRequired,
      description,
      low_stock_threshold,
      name
    } = this.state;

    const { featureId, disabled } = this.props;
    return (
      <div>
        {this.state.error ? <ErrorModal error={this.state.error} /> : null}
        {!featureId ? (
          this.props.isInner ? (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleOpen}
              disabled={disabled}
            >
              Create new feature
            </Button>
          ) : (
            <AddButton disabled={disabled}>
              <Fab
                size="medium"
                onClick={this.handleOpen}
                color="primary"
                aria-label="Add"
              >
                <AddIcon />
              </Fab>
            </AddButton>
          )
        ) : null}
        {featureId ? (
          <EditIcon onClick={!disabled ? this.handleOpen : undefined} />
        ) : null}

        <Dialog
          aria-labelledby="new-feature"
          open={open}
          onClose={this.handleClose}
          fullWidth={true}
          maxWidth={"lg"}
        >
          <NewFeaturesForm>
            <DialogTitle id="new-feature">
              {featureId ? "Edit" : "Create new"} Feature
            </DialogTitle>
            <form onSubmit={this.handleSubmit}>
              <TextField
                id="name"
                fullWidth={true}
                name="name"
                label="Feature Name"
                margin="normal"
                required={true}
                value={name}
                onChange={event => {
                  this.setState({ name: event.target.value });
                }}
              />

              <TextField
                id="description"
                fullWidth={true}
                name="description"
                label="Feature Description"
                margin="normal"
                value={description}
                onChange={event => {
                  this.setState({ description: event.target.value });
                }}
              />

              <Formbox>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="type">Type</InputLabel>
                  <Select
                    disabled={featureId ? true : false}
                    id="type"
                    value={featureType}
                    onChange={event => {
                      this.setState({ featureType: event.target.value });
                    }}
                  >
                    $
                    {featureTypes.map((featureType, index) => (
                      <MenuItem key={featureType} value={index}>
                        {featureType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {featureTypes[featureType].toLowerCase() !== "advance" &&
                featureTypes[featureType].toLowerCase() !== "recommendation" &&
                featureTypes[featureType].toLowerCase() !== "low-stock" ? (
                  <FormInputInlineFlex>
                    <FormControl fullWidth={true} margin="normal">
                      <InputLabel htmlFor="social_proof_event">
                        Event
                      </InputLabel>
                      <Select
                        id="social_proof_event"
                        value={socialproofEvent}
                        onChange={event => {
                          this.setState({
                            socialproofEvent: event.target.value
                          });
                        }}
                      >
                        $
                        {socialproofEvents.map((socialproofEvent, index) => (
                          <MenuItem key={socialproofEvent} value={index}>
                            {socialproofEvent}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <TextField
                      id="social_proof_duration"
                      fullWidth={true}
                      label="Duration"
                      type="text"
                      margin="normal"
                      required={true}
                      value={social_proof_duration}
                      onChange={event => {
                        this.setState({
                          social_proof_duration: event.target.value
                        });
                      }}
                    />

                    {featureTypes[featureType].toLowerCase() ===
                    "socialproof-ecount" ? (
                      <TextField
                        id="social_proof_threshold"
                        fullWidth={true}
                        inputProps={{ min: 1 }}
                        label="Threshold"
                        type="number"
                        margin="normal"
                        required={true}
                        value={social_proof_threshold}
                        onChange={event => {
                          this.setState({
                            social_proof_threshold: event.target.value
                          });
                        }}
                      />
                    ) : null}
                  </FormInputInlineFlex>
                ) : null}

                {featureTypes[featureType].toLowerCase() ===
                "recommendation" ? (
                  <FormInputInlineFlex>
                    <FormControl fullWidth={true} margin="normal">
                      <InputLabel htmlFor="recommendation_config_type">
                        Event
                      </InputLabel>
                      <Select
                        id="recommendation_config_type"
                        value={recommendationType}
                        onChange={event => {
                          this.setState({
                            recommendationType: event.target.value
                          });
                        }}
                      >
                        $
                        {recommendationTypes.map(
                          (recommendationType, index) => (
                            <MenuItem key={recommendationType} value={index}>
                              {recommendationType}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                    <TextField
                      id="recommendation_config_title"
                      fullWidth={true}
                      inputProps={{ max: 200, min: 1 }}
                      label="Title"
                      type="text"
                      margin="normal"
                      required={true}
                      value={recommendation_config_title}
                      onChange={event => {
                        this.setState({
                          recommendation_config_title: event.target.value
                        });
                      }}
                    />
                    <TextField
                      id="recommendation_config_total_num"
                      fullWidth={true}
                      inputProps={{ max: 99, min: 1 }}
                      label="TotalNum"
                      type="number"
                      margin="normal"
                      required={true}
                      value={recommendation_config_total_num}
                      onChange={event => {
                        this.setState({
                          recommendation_config_total_num: event.target.value
                        });
                      }}
                    />
                    <TextField
                      id="recommendation_config_num_per_page"
                      fullWidth={true}
                      inputProps={{ max: 99, min: 1 }}
                      label="NumPerPage"
                      type="number"
                      margin="normal"
                      required={true}
                      value={recommendation_config_num_per_page}
                      onChange={event => {
                        this.setState({
                          recommendation_config_num_per_page: event.target.value
                        });
                      }}
                    />
                  </FormInputInlineFlex>
                ) : null}

                {/*low stock */}
                {featureTypes[featureType].toLowerCase() === "low-stock" ? (
                  <FormInputInlineFlex>
                    <TextField
                      id="low_stock_threshold"
                      fullWidth={true}
                      inputProps={{ min: 1 }}
                      label="Threshold"
                      type="number"
                      margin="normal"
                      required={true}
                      value={low_stock_threshold}
                      onChange={event => {
                        this.setState({
                          low_stock_threshold: event.target.value
                        });
                      }}
                    />
                  </FormInputInlineFlex>
                ) : null}
              </Formbox>
              <Formbox>
                <Typography variant="h5">Variations</Typography>

                <TextField
                  id="percent"
                  fullWidth={true}
                  inputProps={{ max: 100, min: 0 }}
                  name="percent"
                  label="Variant Percent"
                  type="number"
                  margin="normal"
                  required={true}
                  value={percent}
                  onChange={event => {
                    this.setState({ percent: event.target.value });
                  }}
                />
              </Formbox>
              <Formbox>
                <Typography variant="h5">Goals</Typography>
                <FormInputInlineFlex>
                  <TextField
                    id="conversions_goal_simple_size"
                    fullWidth={true}
                    name="conversions_goal_simple_size"
                    label="Conversions Goal Simple Size"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="number"
                    margin="normal"
                    required={true}
                    value={conversions_goal_simple_size}
                    onChange={event => {
                      this.setState({
                        conversions_goal_simple_size: event.target.value
                      });
                    }}
                  />

                  <TextField
                    id="revenue_per_visitor_goal_simple_size"
                    fullWidth={true}
                    name="revenue_per_visitor_goal_simple_size"
                    label="Revenue per Visitor Goal Simple Size"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="number"
                    margin="normal"
                    required={true}
                    value={revenue_per_visitor_goal_simple_size}
                    onChange={event => {
                      this.setState({
                        revenue_per_visitor_goal_simple_size: event.target.value
                      });
                    }}
                  />

                  <TextField
                    id="revenue_per_converter_goal_simple_size"
                    fullWidth={true}
                    name="revenue_per_converter_goal_simple_size"
                    label="Revenue per Converter Goal Simple Size"
                    InputLabelProps={{
                      shrink: true
                    }}
                    type="number"
                    margin="normal"
                    required={true}
                    value={revenue_per_converter_goal_simple_size}
                    onChange={event => {
                      this.setState({
                        revenue_per_converter_goal_simple_size:
                          event.target.value
                      });
                    }}
                  />
                </FormInputInlineFlex>
                <Typography variant="h6">Custom Goal</Typography>
                <FormInputInlineFlex>
                  <TextField
                    id="custom_goal_name"
                    fullWidth={true}
                    name="custom_goal_name"
                    label="Name"
                    type="text"
                    margin="normal"
                    value={custom_goal_name}
                    onKeyUp={this.handleCustomGoal}
                    required={customGoalRequired}
                    onChange={event => {
                      this.setState({ custom_goal_name: event.target.value });
                    }}
                  />
                  <TextField
                    id="custom_goal_simple_size"
                    fullWidth={true}
                    name="custom_goal_simple_size"
                    label="Simple Size"
                    type="number"
                    margin="normal"
                    value={custom_goal_simple_size}
                    onKeyUp={this.handleCustomGoal}
                    required={customGoalRequired}
                    onChange={event => {
                      this.setState({
                        custom_goal_simple_size: event.target.value
                      });
                    }}
                  />
                  <TextField
                    id="custom_goal_convert_event"
                    fullWidth={true}
                    name="custom_goal_convert_event"
                    label="Convert Event"
                    type="text"
                    margin="normal"
                    value={custom_goal_convert_event}
                    onKeyUp={this.handleCustomGoal}
                    required={customGoalRequired}
                    onChange={event => {
                      this.setState({
                        custom_goal_convert_event: event.target.value
                      });
                    }}
                  />
                </FormInputInlineFlex>
              </Formbox>

              <LoadingBtn
                disabled={submitLoading}
                variant="contained"
                color="primary"
                type="submit"
              >
                {featureId ? "Update" : "Create"}
              </LoadingBtn>
            </form>
          </NewFeaturesForm>
        </Dialog>
      </div>
    );
  }
}

export default FeatureFromModal;
export {
  featureTypes,
  socialproofEvents,
  recommendationTypes,
  FeatureFromModal
};
