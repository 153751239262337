import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowRight";
import { accounting } from "accounting";
import { ResultsTable } from "./atom";

import { BOOST__DOMAIN } from "../../../Utils";
import { getRateName } from "./helper";

class GoalTable extends React.Component {
  handleClick(rowClassName) {
    document.querySelector(rowClassName).classList.toggle("goals-version-show");
  }

  render() {
    const goals = this.props.data;
    const name = this.props.name;

    return goals.length ? (
      <ResultsTable>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width="40%">VARIATION</TableCell>
              <TableCell align="right">VISITORS</TableCell>
              <TableCell align="right">CONVERTERS</TableCell>
              <TableCell align="right">{getRateName(name)}</TableCell>
              <TableCell align="right">UPLIFT</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>

          {goals.map((goal, index) => {
            let rowClassName = `goalsRows-${+new Date()}-${goal.name.replace(
              /[ ]/g,
              ""
            )}-${goal.id}`;
            return goal.name ? (
              <TableBody key={goal.id + index}>
                <TableRow
                  className={`goalsRows ${rowClassName}`}
                  onClick={this.handleClick.bind(this, [`.${rowClassName}`])}
                >
                  <TableCell>{goal.name}</TableCell>
                  <TableCell align="right">
                    {accounting.formatNumber(goal.visitors)}
                  </TableCell>
                  <TableCell align="right">
                    {accounting.formatNumber(goal.converters)}
                  </TableCell>
                  <TableCell align="right">
                    {goal.conversionRate >= 0
                      ? accounting.formatMoney(
                          goal.conversionRate,
                          BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol
                        )
                      : goal.conversionRate}
                  </TableCell>
                  <TableCell align="right">{goal.uplift}</TableCell>
                  <TableCell align="right" className="goalsRows-icon">
                    <KeyboardArrowDownIcon />
                  </TableCell>
                </TableRow>

                {goal.tableIteration.map(version => {
                  return (
                    version && (
                      <TableRow
                        key={version.version_id}
                        className="version-table-row"
                      >
                        <TableCell className="version-table-cell">
                          {version.version_name}
                        </TableCell>
                        <TableCell align="right">
                          {accounting.formatNumber(version.visitors)}
                        </TableCell>
                        <TableCell align="right">
                          {accounting.formatNumber(version.converters)}
                        </TableCell>
                        <TableCell align="right">
                          {version.conversion_rate}
                        </TableCell>
                        <TableCell align="right">{version.uplift}</TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    )
                  );
                })}
              </TableBody>
            ) : null;
          })}
        </Table>
      </ResultsTable>
    ) : null;
  }
}

export default GoalTable;
export { getRateName };
