import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";

import axios from "axios";
import { API_OVERVIEW } from "../../Utils";
import Loading from "../../components/Loading";
import {accounting} from "accounting";

const MetricsContainer = styled(Paper)`
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
`;
const Metrics = styled.div`
  display: flex;
  min-height: 72px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  position: relative;
`;
const Metric = styled.div`
  font-size: 36px;
  font-weight: 100;
  width: 25%;
  p {
    font-size: 14px;
  }
`;

const PerformanceTrackerData = [
  {
    Title: "Visitors viewed features",
    Key: "visitors"
  },
  {
      Title: "Conversions",
      Key: "conversions"
  },
  {
      Title: "Conversion Rate",
      Key: "conversion_rate"
  },
  {
    Title: "Live Features",
    Key: "live_features"
  }

];

class PerformanceTracker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      trackerData: null,
      error: false
    };
  }
  async fetchData() {
    try {
      const result = await axios.get(API_OVERVIEW);
      const overviewData = result.data.data;


      this.setState({
        trackerData: overviewData
      });
    } catch (error) {
      this.setState({
        error: true
      });
    }
  }
  async componentDidMount() {
    this.fetchData();
    this.interval = setInterval(() => this.fetchData(), 2000 * 60);
  }

  render() {
    const trackerData = this.state.trackerData;
    return (
      <div>
        {this.state.error ? console.error(this.state.error) : null}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MetricsContainer elevation={1}>
              <Typography variant="h6">Overview</Typography>
              <Metrics>
                {!trackerData ? (
                  <Loading />
                ) : (
                  PerformanceTrackerData.map(tracker => (
                    <Metric key={tracker.Key}>
                      <p>{tracker.Title}</p>
                      <span>{accounting.formatNumber(trackerData[tracker.Key])}</span>
                    </Metric>
                  ))
                )}
              </Metrics>
            </MetricsContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default PerformanceTracker;
