import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const Center = styled.div`
   {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  }
});

function Loading(props) {
  const { classes, position } = props;
  return (
    <div>
      {position === "center" ? (
        <Center>
          <CircularProgress
            size={30}
            thickness={2.5}
            className={classes.progress}
          />
        </Center>
      ) : (
        <CircularProgress
          size={30}
          thickness={2.5}
          className={classes.progress}
        />
      )}
    </div>
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Loading);
