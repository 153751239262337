function _roundIt(v) {
  return parseFloat(v.toFixed(0));
}

let divisors = {
  days: 1000 * 60 * 60 * 24
  // hours: 1000 * 60 * 60
};

let DateDaysDiff = function(date1, date2) {
  let difference = Math.floor(date1 - date2);
  return _roundIt(difference / divisors.days); // days
};

function CalcDate(dates) {
  let totalDays = 0;

  for (let i = 0; i < dates.length; i++) {
    let date = dates[i];
    let date1 = date.end_at ? new Date(date.end_at) : new Date();
    let date2 = new Date(date.start_at);
    let days = DateDaysDiff(date1, date2);
    date.days = days;
    if (!date.operated_by) {
      date.operated_by = { email: "Boost Scheduler" };
    }
    totalDays += days;
  }

  return {
    totalDays: totalDays,
    dates: dates
  };
}

export default CalcDate;
