import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { accounting } from "accounting";

import {
  featureTypes,
  socialproofEvents,
  recommendationTypes
} from "../../../components/FeatureFromModal";
import { getPublishVersion } from "../../../components/getPublishVersionHelper";

const SettingsContainer = styled(Paper)`
  padding: 24px;
  margin-bottom: 36px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  h3 {
    margin-top: 0;
  }
  ul {
    padding-left: 18px;
  }
  li {
    margin: 4px 0;
    font-size: 14px;
  }
  h5 {
    margin-bottom: 4px;
    & + p {
      margin-top: 4px;
      font-size: 14px;
    }
  }
`;

class featureSettings extends React.Component {
  render() {
    const profile = this.props.profile;
    const {
      latest_published_version,
      waiting_publish_version,
      draft_version,
      type,
      state
    } = profile;

    const published_version = getPublishVersion({
      draft_version,
      state,
      latest_published_version,
      waiting_publish_version
    });

    const { variations, goals } = published_version;
    const {
      recommendation_config,
      social_proof_ecount_config,
      social_proof_recency_config,
      social_proof_topk_config,
      low_stock_config
    } = variations[0];

    const config =
      recommendation_config ||
      social_proof_ecount_config ||
      social_proof_recency_config ||
      social_proof_topk_config ||
      low_stock_config;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <SettingsContainer>
              <h3>Goals</h3>
              <ul>
                {goals &&
                  goals.map(goal => {
                    return goal.name ? (
                      <li key={goal.id}>
                        {goal.name} ({accounting.formatNumber(goal.sample_size)}
                        )
                      </li>
                    ) : null;
                  })}
              </ul>
            </SettingsContainer>
            <SettingsContainer>
              <h3>Variant percent</h3>
              <ul>
                {variations &&
                  variations.map(v => {
                    return v.name ? (
                      <li key={v.id}>
                        {" "}
                        {`${v.name}`} (
                        {`${Math.fround(v.percent)}%${
                          v.is_control ? ", Basic" : ""
                        }`}
                        ){" "}
                      </li>
                    ) : null;
                  })}
              </ul>
            </SettingsContainer>
            <SettingsContainer>
              <h3>
                Type <small>({featureTypes[type]})</small>
              </h3>
              <ul>
                {config &&
                  Object.keys(config).map(cfgKey => {
                    let cfgVal = config[cfgKey];
                    if (cfgKey === "event") {
                      cfgVal = socialproofEvents[cfgVal];
                    }
                    if (cfgKey === "type") {
                      cfgVal = recommendationTypes[cfgVal];
                    }
                    return (
                      <li key={cfgKey}>
                        {cfgKey}: {cfgVal}
                      </li>
                    );
                  })}
              </ul>
            </SettingsContainer>
            {/* <SettingsContainer>
              <h3>Triggers</h3>
              <ul>
                <li>Custom JavaScript</li>
                <li>Page URL doesn't contain /carts or /order</li>
                <li>Minimum time in session (seconds) 20</li>
                <li>Device type is tablet or computer</li>
                <li>Times to show per session is 1</li>
              </ul>
            </SettingsContainer> */}
          </Grid>
          <Grid item xs={4}>
            <SettingsContainer>
              <h3>Details</h3>
              <h5>Description</h5>
              <p>{profile.profile.description}</p>
              <h5>Scheduled start date</h5>
              <p>{new Date(profile.created_at).toDateString()}</p>
              <h5>Scheduled end date</h5> <p>— </p>
              <h5>Created by</h5>
              <p>
                {`${
                  profile.created_by
                    ? `${profile.created_by.given_name} ${profile.created_by.family_name} (${profile.created_by.email})`
                    : "Boost Scheduler"
                }`}
              </p>
              <h5>Created at</h5>
              <p>Wed, May 31, 2017 6:24 PM</p>
            </SettingsContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default featureSettings;
