import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import axios from "axios";
import { API_STREAM } from "../../Utils";
import Loading from "../../components/Loading";
import { withStyles } from "@material-ui/core/styles";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";

const LiveStreamContainer = styled(Paper)`
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  .recharts-responsive-container {
    margin-top: 24px;
  }
`;

const LiveStreamSelector = styled.div`
  float: right;
  margin-top: -50px;
  margin-right: 5px;
`;

const CustomTooltipContainer = styled.div`
  padding: 18px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  h6 {
    font-size: 16px;
    margin-bottom: 0;
  }
  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 8px;
    span {
      color: #777;
    }
  }
`;

const CustomTooltip = props => {
  if (props.payload && props.payload.length) {
    return <CustomTooltipInner {...props.payload} />;
  } else {
    return <DefaultTooltipContent {...props} />;
  }
};

const CustomTooltipInner = data => {
  const payload = data[0].payload;
  return (
    <CustomTooltipContainer>
      <Typography variant="h6">{payload.date}</Typography>
      <p>{payload.time}</p>
      {Object.keys(data).map(key => (
        <p key={data[key].name} className={data[key].name}>
          <span>{data[key].name}</span> : {data[key].value}
        </p>
      ))}
    </CustomTooltipContainer>
  );
};

const formatData = data => {
  let streamData = [];
  let labelTime = "";

  if (data && data.length) {
    data.forEach(obj => {
      labelTime = new Date(+obj.end);
      streamData.push({
        date: labelTime.toDateString(),
        time: `${labelTime.toTimeString().substr(0, 5)}`,
        visitors: +obj.visitors,
        converters: +obj.converters
      });
    });
  }
  return streamData;
};

class LiveStreamChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      streamData: null,
      streamType: "visitors",
      error: false
    };
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  async fetchData() {
    try {
      const result = await axios.get(API_STREAM);
      const streamData = formatData(result.data.data.all);
      this.setState({
        streamData: streamData
      });
    } catch (error) {
      this.setState({
        error: error
      });
    }
  }

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(() => this.fetchData(), 3000 * 60);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    const data = this.state.streamData;
    const theme = this.props.theme;
    return (
      <div>
        {this.state.error ? console.error(this.state.error): null}

        {!data ? (
          <Loading position="center" />
        ) : (
          <div>
            <LiveStreamSelector>
              <Select
                value={this.state.streamType}
                onChange={this.handleChange}
                inputProps={{
                  name: "streamType",
                  id: "streamType"
                }}
              >
                <MenuItem value="visitors">Visitors</MenuItem>
                <MenuItem value="converters">Converters</MenuItem>
                <MenuItem value="all">All</MenuItem>
              </Select>
            </LiveStreamSelector>
            <ResponsiveContainer
              width="100%"
              height={320}
              margin={{ top: 25, right: 0, bottom: 0, left: 0 }}
            >
              <LineChart data={data}>
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  stroke="#e4e4e4"
                />
                <XAxis
                  dataKey="time"
                  interval={5}
                  stroke="#bdc3c7"
                  fontSize="12"
                />
                <YAxis
                  axisLine={false}
                  fontSize="12"
                  stroke="#bdc3c7"
                  tickLine={false}
                />
                <Tooltip content={<CustomTooltip data={data} />} />
                {/* <Legend verticalAlign="top" align="right" height={36} /> */}
                {(this.state.streamType === "visitors" ||
                  this.state.streamType === "all") && (
                  <Line
                    className="visitors_chart"
                    type="monotone"
                    dot={false}
                    strokeWidth={2}
                    dataKey="visitors"
                    stroke={theme.palette.primary.main}
                  />
                )}
                {(this.state.streamType === "converters" ||
                  this.state.streamType === "all") && (
                  <Line
                    className="converters_chart"
                    type="monotone"
                    dot={false}
                    strokeWidth={2}
                    dataKey="converters"
                    stroke="#82ca9d"
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    );
  }
}

class LiveStream extends React.Component {
  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LiveStreamContainer>
              <Typography variant="h6">Live Traffic</Typography>
              <LiveStreamChart {...this.props} />
            </LiveStreamContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles({}, { withTheme: true })(LiveStream);
