import React from "react";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import LoadingBtn from "../../components/LoadingBtn";
import PauseIcon from "@material-ui/icons/Pause";
import ExportIcon from "@material-ui/icons/CloudDownload";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import PlayArrow from "@material-ui/icons/PlayArrow";
import axios from "axios";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FeatureFromModal from "../../components/FeatureFromModal";
import { withStyles } from "@material-ui/core/styles";
import ReleaseNoteDialog from "./ReleaseNoteDialog";
import ErrorModal from "../../components/ErrorModal";
import ConfirmModal from "../../components/ConfirmModal";
import { getFileNameFromXHRResult } from "../../helper";

import {
  BOOST__PERMISSIONS,
  BOOST__DOMAINID,
  BOOST__FEATUREID,
  API_FEATURES_PAUSE,
  API_FEATURES_UNPAUSE,
  API_FEATURES_RELEASE,
  API_FEATURES_ARCHIVE,
  API_FEATURES_UNARCHIVE,
  API_FEATURES_DELETE,
  API_FEATURES_EXPROT
} from "../../Utils";

const EHead = styled.div`
  padding: 24px;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  h1 {
    margin-top: 0;
    font-size: 30px;
  }
  p {
    font-size: 14px;
  }
  .indicator::before {
    content: "";
    display: inline-block;
    margin-right: 8px;
    width: 8px;
    height: 8px;
    background: #c5d0de;
    border-radius: 8px;
  }
  .LIVE::before {
    background: #62c370;
  }
  .DRAFT::before {
    background: #ff9800;
  }
`;
const EBody = styled.div``;
const MetricsContainer = styled(Paper)`
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 24px;
  h3 {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
  }
`;
const Metrics = styled.div`
  min-height: 72px;
  width: 100%;
`;
const Metric = styled.div`
  font-size: 36px;
  font-weight: 100;
`;
const SubTitle = styled.div`
  font-size: 14px;
  font-weight: 100;
  color: #999;
  strong {
    color: #333;
  }
`;

const FeaturesTabs = styled.div`
  margin: 0 -24px -24px;
`;
const FeaturesHeadActions = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 24px;
  margin-top: -13px;
  bottom: 0;
  button {
    margin-right: 16px;
    &.last {
      border-radius: 4px;
      background-color: #ebeeff;
      width: 36px;
      height: 36px;
      margin-right: 0;
    }
  }
  > a {
    display: inline-flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: 4px;

    > div {
      display: inline-flex;
    }

    &:hover {
      opacity: 0.8;
    }

    &.start {
      cursor: pointer;
    }
    &.middle {
      border-radius: 0;
    }
  }
`;
const FeaturesHead = styled.div`
  position: relative;
`;
const TipsContainer = styled.div`
  margin: 24px 0;
  text-align: center;
  svg,
  p {
    display: inline-block;
    vertical-align: middle;
    margin: 0 8px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const MoreButtonWrapper = styled.div`
  display: inline-block;
  margin-left: 16px;
`;

const Text = styled.span`
  padding-left: 5px;
`;

const FeaturesSummary = [
  {
    Title: "Total visitors",
    Key: "total_visitors",
    SubTitle: "in the last 24 hours",
    SubKey: "last24h_visitors"
  },
  {
    Title: "Total conversions",
    Key: "total_converters",
    SubTitle: "in the last 24 hours",
    SubKey: "last24h_converters"
  },
  {
    Title: "Live visitors",
    Key: "live_visitors",
    SubTitle: "in the last hours",
    SubKey: "last1h_visitors"
  }
];

const EXPORT = "EXPORT";
const FeaturesHeadActionsDropMenu = [EXPORT, "ARCHIVE", "DELETE"];

const style = theme => ({
  editBtn: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText
  },
  disableBtn: {
    backgroundColor: "grey",
    color: "white"
  }
});

class DetailsHead extends React.Component {
  openReleaseNoteDialog = () => {
    this.setState({
      releaseNoteDialogIsOpen: true
    });
  };

  closeReleaseNoteDialog = text => {
    this.setState({
      openReleaseNoteDialog: false
    });

    this.setState({
      stateCode: "LOADING"
    });

    this.callReleaseAPI(text);
  };

  state = {
    anchorEl: null,
    state: this.props.data.state,
    stateCode: this.props.data.state,
    releaseNoteDialogIsOpen: false,
    showConfirmModal: false
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = ({ label, state }) => {
    switch (label) {
      case "EXPORT":
        this.callExportAPI();
        break;
      case "ARCHIVE":
        if (state === "ARCHIVED") {
          this.callUnArchiveAPI();
        } else {
          this.callArchiveAPI();
        }
        break;
      case "DELETE":
        this.setState({
          showConfirmModal: true
        });
        break;
      default:
        break;
    }

    this.setState({ anchorEl: null });
  };

  callExportAPI = async () => {
    try {
      const result = await axios.request({
        url: API_FEATURES_EXPROT,
        method: "get",
        responseType: "blob" // should use responseType to blob, otherwise it the response data is not right
      });

      if (result) {
        if (result.status === 200) {
          // use inset a in body to download file, much simpler
          let url = window.URL.createObjectURL(new Blob([result.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          const filename = getFileNameFromXHRResult(result);
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        } else {
          console.log("export failed: ", result);
        }
      } else {
        alert("export failed");
      }
    } catch (error) {
      this.setState({
        error
      });
    }
  };

  callArchiveAPI = async () => {
    try {
      const result = await axios.post(API_FEATURES_ARCHIVE);
      if (result) {
        if (result.data.code === 200) {
          window.location.reload();
        } else {
          console.log("archived failed error", result);
        }
      } else {
        alert("archived failed");
      }
    } catch (error) {
      this.setState({
        error
      });
    }
  };

  callUnArchiveAPI = async () => {
    try {
      const result = await axios.post(API_FEATURES_UNARCHIVE);
      if (result) {
        if (result.data.code === 200) {
          window.location.reload();
        } else {
          console.log("unarchived failed error", result);
        }
      } else {
        alert("unarchived failed");
      }
    } catch (error) {
      this.setState({
        error
      });
    }
  };

  callDeleteAPI = async () => {
    try {
      const result = await axios.delete(API_FEATURES_DELETE);
      if (result) {
        if (result.data.code === 200) {
          window.location.href = `/d/${BOOST__DOMAINID}/features`;
        } else {
          console.log("delete failed error", result);
        }
      } else {
        alert("delete failed");
      }
    } catch (error) {
      this.setState({
        error
      });
    }
  };

  closeConfirmModal = () => {
    this.setState({
      showConfirmModal: false
    });
  };

  callReleaseAPI = async releaseNote => {
    try {
      if (releaseNote) {
        const result = await axios.post(API_FEATURES_RELEASE, {
          release_note: releaseNote
        });
        if (result && result.status === 200) {
          window.location.href = `/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}`;
        } else {
          alert("release failed");
        }
      } else {
        window.location.reload();
      }
    } catch (error) {
      this.setState({
        error
      });
    }
  };

  changeStates = async state => {
    this.setState({
      stateCode: "LOADING"
    });
    const _this = this;
    try {
      const result = await axios.post(
        state === "LIVE" ? API_FEATURES_PAUSE : API_FEATURES_UNPAUSE
      );
      if (result) {
        if (result.data.data.state === 1) {
          _this.setState({
            stateCode: "LIVE",
            state: "LIVE"
          });
        } else if (result.data.data.state === 0) {
          _this.setState({
            stateCode: "PAUSED",
            state: "PAUSED"
          });
        }
      }
    } catch (error) {
      this.setState({
        error,
        stateCode: state
      });
    }
  };

  render() {
    const {
      feature_name,
      last_updated_at,
      feature_id,
      draft_version,
      waiting_publish_version
    } = this.props.data;

    const isDraft = this.props.isDraft;
    const classes = this.props.classes;
    const { anchorEl, stateCode, state, releaseNoteDialogIsOpen } = this.state;
    const open = Boolean(anchorEl);
    const isDisabled = state === "ARCHIVED";

    const exportMenu = FeaturesHeadActionsDropMenu.find(
      label => label === EXPORT
    );

    return (
      <div>
        <ErrorModal error={this.state.error} />
        <FeaturesHead>
          <h1>{feature_name}</h1>
          <ReleaseNoteDialog
            open={releaseNoteDialogIsOpen}
            handleClose={this.closeReleaseNoteDialog}
            historyText={
              waiting_publish_version
                ? waiting_publish_version.release_note
                : undefined
            }
          />
          <p>
            {/* if state is archived, not display draft state, display itself */}
            {state !== "ARCHIVED" && isDraft && draft_version ? (
              <span className={`indicator DRAFT`}>DRAFT</span>
            ) : (
              <span>
                <span className={`indicator ${state}`}>{state}</span>
                {last_updated_at &&
                  "— " + new Date(last_updated_at).toDateString()}
              </span>
            )}
          </p>
          {BOOST__PERMISSIONS.edit ? (
            <FeaturesHeadActions>
              {isDraft && draft_version ? (
                <LoadingBtn
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={stateCode === "LOADING" ? true : false}
                  plainDisabled={isDisabled}
                  onClick={this.openReleaseNoteDialog}
                >
                  Release
                </LoadingBtn>
              ) : (
                <LoadingBtn
                  variant="contained"
                  size="medium"
                  color="primary"
                  disabled={stateCode === "LOADING" ? true : false}
                  plainDisabled={isDisabled}
                  onClick={() => {
                    this.changeStates(state);
                  }}
                >
                  {stateCode === "LIVE" ? <PauseIcon /> : null}
                  {stateCode === "PAUSED" ? <PlayArrow /> : null}
                  {state === "LIVE" ? "Pause" : "Start"} feature
                </LoadingBtn>
              )}
              {/* eslint-disable-next-line */}
              <a
                className={`start ${classes.editBtn} ${
                  isDisabled && classes.disableBtn
                }`}
              >
                <FeatureFromModal
                  featureId={feature_id}
                  disabled={isDisabled}
                />
              </a>
              <MoreButtonWrapper>
                <MoreVertIcon onClick={this.handleClick} />
              </MoreButtonWrapper>
              <Menu
                id="feature-actions-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={this.handleClose}
              >
                {FeaturesHeadActionsDropMenu.map(label => (
                  <MenuItem
                    key={label}
                    onClick={() => this.handleClose({ label, state })}
                  >
                    {label === "ARCHIVE" && isDisabled ? "UNARCHIVE" : label}
                  </MenuItem>
                ))}
              </Menu>
              <ConfirmModal
                open={this.state.showConfirmModal}
                onConfirm={this.callDeleteAPI}
                onClose={this.closeConfirmModal}
              />
            </FeaturesHeadActions>
          ) : (
            <FeaturesHeadActions>
              <LoadingBtn
                variant="contained"
                size="medium"
                color="primary"
                disabled={stateCode === "LOADING" ? true : false}
                onClick={() => this.handleClose({ label: exportMenu, state })}
              >
                <ExportIcon />
                <Text>export</Text>
              </LoadingBtn>
            </FeaturesHeadActions>
          )}
        </FeaturesHead>
      </div>
    );
  }
}

function getCurrentHash(hashValue) {
  const currentHash = window.location.hash.replace("#", "");
  let currentHashValue = 0;
  if (currentHash) {
    for (let i = 0; i < 3; i++) {
      if (hashValue[i] === currentHash) {
        currentHashValue = i;
      }
    }
  }
  return currentHashValue;
}

const DetailsHeadWithStyle = withStyles(style, { withTheme: true })(
  DetailsHead
);
export {
  EHead,
  EBody,
  MetricsContainer,
  Metrics,
  Metric,
  SubTitle,
  FeaturesSummary,
  DetailsHead,
  DetailsHeadWithStyle,
  FeaturesTabs,
  getCurrentHash,
  TipsContainer
};
