import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";

import { BOOST__USER, BOOST__DOMAIN, domains } from "../Utils";

const styles = {
  iconButton: {
    color: "#fff",
    fontSize: "1rem",
    "&:hover": {
      background: "none"
    }
  }
};

class UserDomainMenu extends React.Component {
  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChangeProps = id => {
    this.handleClose();
    const pathname = `/d/${id}/overview`;
    window.location.pathname = pathname;
  };

  logout = () => {
    window.location.pathname = "/api/auth/logout";
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? "long-menu" : undefined}
          aria-haspopup="true"
          onClick={this.handleClick}
          className={classes.iconButton}
        >
          {BOOST__DOMAIN.name}
          <KeyboardArrowDownIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              width: 200
            }
          }}
        >
          {domains.map(domain => (
            <MenuItem
              key={domain.id}
              onClick={() => {
                this.handleChangeProps(domain.id);
              }}
            >
              {domain.name}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem>{BOOST__USER.email}</MenuItem>
          <MenuItem>My profile</MenuItem>
          <MenuItem
            onClick={() => {
              this.logout();
            }}
          >
            Log out
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UserDomainMenu);
