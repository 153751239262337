import React from "react";
import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";

import { BOOST__DOMAIN } from "../../Utils";

const THEAD_ROWS = [
  { id: "name", numeric: false, disablePadding: true, label: "Name" },
  {
    id: "variant_percent",
    numeric: true,
    disablePadding: false,
    label: "Variant percentage"
  },
  {
    id: "visitors_viewed_features",
    numeric: true,
    disablePadding: false,
    label: "Visitors viewed features"
  },
  {
      id: "conversion",
      numeric: true,
      disablePadding: false,
      label: "Conversion"
  },
  {
    id: "conversion_rate",
    numeric: true,
    disablePadding: false,
    label: "Conversion Rate"
  },
  {
    id: "revenue",
    numeric: true,
    disablePadding: false,
    label: `Revenue (${BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol})`
  },
  {
    id: "last_updated_at",
    numeric: true,
    disablePadding: false,
    label: "Date Modified"
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = domain => event => {
    onRequestSort(event, domain);
  };

  return (
    <TableHead>
      <TableRow>
        {THEAD_ROWS.map(row => {
          return (
            <TableCell
              key={row.id}
              numeric={row.numeric ? "true" : "false"}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip
                title="Sort"
                placement={row.numeric ? "bottom-end" : "bottom-start"}
                enterDelay={300}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

export default EnhancedTableHead;
