import React from "react";
import axios from "axios";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MainLayout from "../../components/MainLayout";
import {
  BOOST__DOMAINID,
  API_FEATURES_DETAILS,
  API_FEATURES_DETAILS_v2
} from "../../Utils";
import Overview from "./overview";
import TestResults from "./testResults";
import Settings from "./settings";
import Draft from "./draft";
import Loading from "../../components/Loading";
import ErrorModal from "../../components/ErrorModal";
import styled from "styled-components";
import {
  EHead,
  EBody,
  DetailsHeadWithStyle,
  FeaturesTabs,
  getCurrentHash
} from "./atom";

const hashValue = {
  0: "overview",
  1: "testResults",
  2: "settings"
};

const CenterLoading = styled.div`
  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: 120px;
  }
`;

class BoostFeaturesDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      goal: null,
      timeline: null,
      settingProfile: null,
      value: props.initHashValue
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event, newValue) {
    this.setState({
      value: newValue
    });
    window.location.hash = hashValue[newValue];
  }
  async componentDidMount() {
    try {
      const result = await axios.get(API_FEATURES_DETAILS);
      const result2 = await axios.get(API_FEATURES_DETAILS_v2);
      const data = result.data.data;
      const data2 = result2.data.data;

      if (this.props.isDraft) {
        this.setState({
          value: -1
        });
      }

      this.setState({
        data: data,
        timeline: data2.timeline,
        settingProfile: data2,
        goal: data.goal
      });
    } catch (error) {
      this.setState({
        error
      });
    }
  }

  render() {
    const { value, goal, data, timeline, settingProfile } = this.state;
    const { isDraft } = this.props;

    return (
      <div>
        {this.state.error ? <ErrorModal error={this.state.error} /> : null}
        <MainLayout
          pageTitle="Feature Detail"
          pageBackUrl={"/d/" + BOOST__DOMAINID + "/features"}
        >
          <EHead>
            {data && <DetailsHeadWithStyle isDraft={isDraft} data={data} />}
            {data && !isDraft && (
              <FeaturesTabs>
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  indicatorColor="primary"
                >
                  <Tab label="Overview" />
                  <Tab label="Test results" />
                  <Tab label="Settings" />
                </Tabs>
              </FeaturesTabs>
            )}
          </EHead>
          <EBody>
            {!data ? (
              <CenterLoading>
                <Loading />
              </CenterLoading>
            ) : null}
            {data && isDraft && <Draft data={data} profile={settingProfile} />}
            {value === 0 && data && (
              <Overview data={data} timeline={timeline} />
            )}
            {value === 1 && goal && <TestResults goal={goal} />}
            {value === 2 && data && (
              <Settings data={data} profile={settingProfile} />
            )}
            {value === 3 && data && (
              <Settings data={data} profile={settingProfile} />
            )}
          </EBody>
        </MainLayout>
      </div>
    );
  }
}
BoostFeaturesDetails.defaultProps = {
  initHashValue: getCurrentHash(hashValue)
};
export default BoostFeaturesDetails;
