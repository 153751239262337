import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

const formatData = data => {
  let featuresDatas = [];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  data.forEach(obj => {
    if (!obj) {
      return;
    }
    let itemData = {
      name: "",
      id: "",
      isRevenueGoal: false,
      table: [],
      tableSeries: [],
      tableDailySeries: []
    };
    itemData.name = obj.name;
    itemData.id = obj.id;
    itemData.isRevenueGoal = obj.is_revenue_goal;

    if (obj.variant_results.length) {
      obj.variant_results.forEach(result => {
        itemData.table.push({
          id: result.variant_id,
          name: result.variant_name,
          conversionRate: result.conversion_rate,
          converters: result.converters,
          visitors: result.visitors,
          uplift: `${result.uplift}`,
          isControl: result.is_control,
          tableIteration: result.version_stats
        });

        if (!result.is_control && result.cumulative_series.length) {
          result.cumulative_series.forEach(serie => {
            const serieDate = new Date(+serie.timestamp);
            itemData.tableSeries.push({
              date: serieDate.toDateString(),
              xDadte: `${
                monthNames[serieDate.getMonth()]
              } ${serieDate.getDate()}`,
              resultName: result.variant_name,
              uplift: `${serie.uplift}`,
              visitors: `${serie.visitors}`,
              converters: `${serie.converters}`,
              revenue: `${serie.revenue}`,
              rate: `${serie.rate}`,
              control_visitors: `${serie.controlVisitors}`,
              control_converters: `${serie.controlConverters}`,
              control_revenue: `${serie.controlRevenue}`,
              control_rate: `${serie.controlRate}`
            });
          });
        }

        if (!result.is_control && result.daily_series.length) {
          result.daily_series.forEach(serie => {
            const serieDate = new Date(+serie.timestamp);
            itemData.tableDailySeries.push({
              date: serieDate.toDateString(),
              xDadte: `${
                monthNames[serieDate.getMonth()]
              } ${serieDate.getDate()}`,
              resultName: result.variant_name,
              uplift: `${serie.uplift}`,
              visitors: `${serie.visitors}`,
              converters: `${serie.converters}`,
              revenue: `${serie.revenue}`,
              rate: `${serie.rate}`,
              control_visitors: `${serie.controlVisitors}`,
              control_converters: `${serie.controlConverters}`,
              control_revenue: `${serie.controlRevenue}`,
              control_rate: `${serie.controlRate}`
            });
          });
        }
      });
    }

    featuresDatas.push(itemData);
  });

  return featuresDatas;
};

const TestResultsContainer = styled(Paper)`
  padding: 24px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  .recharts-responsive-container {
    margin-top: 24px;
  }
`;

const CustomTooltipContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  > div {
    display: inline-block;
    vertical-align: top;
    margin-right: 24px;
    p {
      color: #777;
    }
  }
  h6 {
    font-size: 14px;
    margin-bottom: 12px;
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 8px;
    span {
      color: #777;
    }
  }
`;

const ResultsTable = styled.div`
  thead th {
    border-bottom: 1px solid #b1b1b1;
    text-transform: capitalize;
  }
  tbody td {
    border-bottom: 1px solid #e4e4e4;
  }
  tbody td,
  thead th {
    padding: 8px;
  }
  tbody tr {
    height: auto;
    &:hover {
      background: #f9fafb;
    }
  }
  .version-table-cell {
    padding-left: 24px;
  }
  .version-table-row {
    display: none;
  }
  .goalsRows {
    cursor: pointer;
  }
  .goalsRows-icon svg {
    transition: all 0.15s;
    transform: rotate(90deg);
    color: rgba(0, 0, 0, 0.54);
  }
  .goals-version-show {
    .goalsRows-icon svg {
      transform: rotate(270deg);
    }
    & ~ tr {
      display: table-row;
    }
  }
`;

export {
  formatData,
  TestResultsContainer,
  CustomTooltipContainer,
  ResultsTable
};
