import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)"
  }
});

const ErrorModal = ({ error: _error, classes }) => {
  const [error, setError] = useState(_error);

  useEffect(() => {
    setError(_error);
  }, [_error]);

  if (!error) {
    return null;
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={error ? true : false}
      onClose={() => setError(null)}
    >
      <div className={classes.paper}>
        <Typography variant="h6" id="modal-title">
          Error
        </Typography>
        <Typography variant="subtitle1" id="simple-modal-description">
          {error.message && error.message}
        </Typography>
        <Typography variant="subtitle2" id="detail-modal-description">
          {error.response && error.response.data && error.response.data.msg}
        </Typography>
        <ErrorModalWrapped />
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  classes: PropTypes.object.isRequired
};
const ErrorModalWrapped = withStyles(styles)(ErrorModal);
export default ErrorModalWrapped;
