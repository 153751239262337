import React from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import { getPublishVersion } from "../../../components/getPublishVersionHelper";

import {
  featureTypes,
  socialproofEvents,
  recommendationTypes
} from "../../../components/FeatureFromModal";

const DraftContainer = styled(Paper)`
  padding: 24px;
  margin-bottom: 36px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  h3 {
    margin-top: 0;
  }
  ul {
    padding-left: 18px;
  }
  li {
    margin: 4px 0;
    font-size: 14px;
  }
  h4 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
  }
  h5 {
    margin-bottom: 4px;
    & + p {
      margin-top: 4px;
      font-size: 14px;
    }
  }
`;

class featureDraft extends React.Component {
  render() {
    const data = this.props.data;
    const profile = this.props.profile;

    const {
      latest_published_version,
      waiting_publish_version,
      draft_version,
      type,
      state
    } = profile;

    const published_version = getPublishVersion({
      isDraftPage: true,
      draft_version,
      state,
      latest_published_version,
      waiting_publish_version
    });

    const { variations } = published_version;
    const {
      recommendation_config,
      social_proof_ecount_config,
      social_proof_recency_config,
      social_proof_topk_config,
      low_stock_config
    } = variations[0];

    const config =
      recommendation_config ||
      social_proof_ecount_config ||
      social_proof_recency_config ||
      social_proof_topk_config ||
      low_stock_config;

    return (
      <div>
        <DraftContainer>
          <h3>Type</h3>
          <p>{featureTypes[type]}</p>
        </DraftContainer>
        <DraftContainer>
          <h3>Variations</h3>
          <Grid container spacing={2}>
            {data &&
              data.draft_version &&
              data.draft_version.variations &&
              data.draft_version.variations.map(variation => (
                <Grid item xs={4} key={variation.id}>
                  <h4>{variation.name}</h4>
                  <ul>
                    <li>
                      is_control: {variation.is_control ? "true" : "false"}
                    </li>
                    <li>variant percent: {variation.percent}</li>
                    {!variation.is_control &&
                    config &&
                    Object.keys(config) &&
                    Object.keys(config).length > 0 ? (
                      <li>
                        config
                        <ul>
                          {config &&
                            Object.keys(config).map(cfgKey => {
                              let cfgVal = config[cfgKey];
                              if (cfgKey === "event") {
                                cfgVal = socialproofEvents[cfgVal];
                              }
                              if (cfgKey === "type") {
                                cfgVal = recommendationTypes[cfgVal];
                              }
                              return (
                                <li key={cfgKey}>
                                  {cfgKey}: {cfgVal}
                                </li>
                              );
                            })}
                        </ul>
                      </li>
                    ) : null}
                  </ul>
                </Grid>
              ))}
          </Grid>
        </DraftContainer>
        <DraftContainer>
          <h3>Goals</h3>
          <Grid container spacing={2}>
            {data &&
              data.draft_version &&
              data.draft_version.goals &&
              data.draft_version.goals.map(goal => {
                return goal.name ? (
                  <Grid item xs={4} key={goal.id}>
                    <h4>{goal.name}</h4>
                    <ul>
                      {Object.keys(goal).map(goalKey => (
                        <li key={goalKey}>
                          {goalKey}: {goal[goalKey].toString()}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                ) : null;
              })}
          </Grid>
        </DraftContainer>
      </div>
    );
  }
}

export default featureDraft;
