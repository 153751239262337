import React from "react";
import { Select, MenuItem } from "@material-ui/core";

const SeriesTypeSelect = ({ seriesType, onChange }) => (
  <Select value={seriesType} onChange={onChange}>
    <MenuItem value="cumulative_series">Cumulative</MenuItem>
    <MenuItem value="daily_series">Daily</MenuItem>
  </Select>
);

const getChartDataBySeries = (goal, seriesType) => {
  switch (seriesType) {
    case "cumulative_series":
      return goal.tableSeries;
    case "daily_series":
      return goal.tableDailySeries;
    default:
      return [];
  }
};

export default SeriesTypeSelect;
export { getChartDataBySeries };
