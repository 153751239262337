import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import { formatData, TestResultsContainer } from "./atom";
import GoalChart from "./chart";
import GoalTable from "./table";
import SeriesTypeSelect, { getChartDataBySeries } from "./SeriesTypeSelect";

const Wrapper = styled(TestResultsContainer)`
  position: relative;
`;

const SelectWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const Detail = ({ goal }) => {
  const [seriesType, setSeriesType] = React.useState("cumulative_series");

  return goal.name ? (
    <Grid item xs={12} key={goal.id}>
      <Wrapper>
        <SelectWrapper>
          <SeriesTypeSelect
            seriesType={seriesType}
            onChange={e => setSeriesType(e.target.value)}
          />
        </SelectWrapper>
        <Typography variant="h6">{goal.name}</Typography>
        <GoalChart
          data={getChartDataBySeries(goal, seriesType)}
          isRevenueGoal={goal.isRevenueGoal}
          name={goal.name}
        />
        <GoalTable name={goal.name} data={goal.table} />
      </Wrapper>
    </Grid>
  ) : null;
};

const featureDetailsTestResults = props => {
  const goals = formatData(props.goal);

  return (
    <div>
      <Grid container spacing={2}>
        {goals.map(goal => (
          <Detail goal={goal} />
        ))}
      </Grid>
    </div>
  );
};

export default featureDetailsTestResults;
