import React from "react";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import Typography from "@material-ui/core/Typography";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import accounting from "accounting";
import { BOOST__DOMAIN } from "../../../Utils";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid
} from "recharts";

import { CustomTooltipContainer } from "./atom";
import { TipsContainer } from "../atom";
import { getRateName } from "./helper";

const CustomTooltip = props => {
  if (props.payload && props.payload.length) {
    return (
      <CustomTooltipInner
        {...props.payload}
        isRevenueGoal={props.isRevenueGoal}
        name={props.name}
      />
    );
  } else {
    return <DefaultTooltipContent {...props} />;
  }
};

const CustomTooltipInner = data => {
  const payload = data[0].payload;
  const isRevenueGoal = data.isRevenueGoal;
  const name = data.name;

  return (
    <CustomTooltipContainer>
      <Typography variant="h6">{payload.date}</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="right">VARIATION</TableCell>
            <TableCell align="right">VISITORS</TableCell>
            <TableCell align="right">CONVERTERS</TableCell>
            <TableCell align="right">REVENUE</TableCell>
            <TableCell align="right">{getRateName(name)}</TableCell>
            <TableCell align="right">UPLIFT</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="right">Control</TableCell>
            <TableCell align="right">{`${payload.control_visitors}`}</TableCell>
            <TableCell align="right">{`${payload.control_converters}`}</TableCell>
            <TableCell align="right">{`${accounting.formatMoney(
              payload.control_revenue,
              BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol
            )}`}</TableCell>
            <TableCell align="right">
              {isRevenueGoal
                ? accounting.formatMoney(
                    payload.control_rate,
                    BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol
                  )
                : `${Math.round(payload.control_rate * 100 * 100) / 100}%`}
            </TableCell>
            <TableCell align="right">baseline</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>{payload.resultName}</TableCell>
            <TableCell align="right">{`${payload.visitors}`}</TableCell>
            <TableCell align="right">{`${payload.converters}`}</TableCell>
            <TableCell align="right">{`${accounting.formatMoney(
              payload.revenue,
              BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol
            )}`}</TableCell>
            <TableCell align="right">
              {isRevenueGoal
                ? accounting.formatMoney(
                    payload.rate,
                    BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol
                  )
                : `${Math.round(payload.rate * 100 * 100) / 100}%`}
            </TableCell>
            <TableCell align="right">{`${payload.uplift}%`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </CustomTooltipContainer>
  );
};

class GoalChart extends React.Component {
  render() {
    const { data, theme, isRevenueGoal, name } = this.props;

    return data.length ? (
      <div>
        <ResponsiveContainer
          width="100%"
          height={200}
          margin={{ top: 25, right: 0, bottom: 0, left: 0 }}
        >
          <LineChart data={data}>
            <CartesianGrid
              strokeDasharray="0"
              vertical={false}
              stroke="#e4e4e4"
            />
            <XAxis
              dataKey="xDadte"
              interval={Math.round(data.length / 20)}
              stroke="#bdc3c7"
              fontSize="12"
            />
            <YAxis
              axisLine={false}
              fontSize="12"
              stroke="#bdc3c7"
              tickLine={false}
            />
            <Tooltip
              content={
                <CustomTooltip
                  data={data}
                  isRevenueGoal={isRevenueGoal}
                  name={name}
                />
              }
            />

            <Line
              className="visitors_chart"
              type="monotone"
              dot={false}
              strokeWidth={2}
              dataKey="uplift"
              stroke={theme.palette.primary.main}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    ) : (
      <TipsContainer>
        <ReportProblemIcon />
        <p>There is no variation data to show for now.</p>
      </TipsContainer>
    );
  }
}

export default withStyles({}, { withTheme: true })(GoalChart);
