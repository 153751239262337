import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Container from "@material-ui/core/Container";
import ArrowBack from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import HomeIcon from "@material-ui/icons/Home";
import FeatureIcon from "@material-ui/icons/Computer";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import withRoot from "../withRoot";

import { BOOST__DOMAINID } from "../Utils";
import UserPropertyMenu from "./UserDomainMenu";

const drawerWidth = 240;
const URI_OVERVIEW = `/d/${BOOST__DOMAINID}/overview`;
const URI_FEATURES = `/d/${BOOST__DOMAINID}/features`;
const fontFamily =
  '"-apple-system", "Roboto", "Helvetica", "Arial", "sans-serif"';

const styles = theme => ({
  root: {
    display: "flex",
    fontFamily: `${fontFamily}`
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    marginLeft: drawerWidth,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    padding: "8px 16px"
  },
  toolbarImg: {
    width: "100%"
  },
  toolbarText: {
    fontWeight: 500,
    paddingTop: "16px",
    fontSize: "26px",
    paddingLeft: "23px",
    visibility: "hidden",
    display: "none"
  },
  sidebarMenu: {
    textDecoration: "none",
    color: "#000",
    display: "block",
    width: "100%",
    paddingLeft: "10px",
    fontWeight: 500
  },
  sidebarList: {
    // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",

    "&.current": {
      borderLeft: `4px solid ${theme.palette.primary.dark}`
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },

  listItemIcon: {
      color: "#102159",
  },

  listItemLink: {
    display: "flex",
    textDecoration: "none",
    width: "100%",
    color: "#102159",
    alignItems: "center"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
});

class BoostMainLayout extends React.Component {
  state = {
    mobileOpen: false
  };

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleBack = pathname => {
    window.location.pathname = pathname;
  };

  render() {
    const { classes, theme, pageTitle, pageBackUrl } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar}>
          <img
            className={classes.toolbarImg}
            src="/static/img/logo.png"
            alt=""
          />
          <span className={classes.toolbarText}>BOOST</span>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <a className={classes.listItemLink} href={URI_OVERVIEW}>
              <ListItemIcon>
                <HomeIcon className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText primary="Overview" />
            </a>
          </ListItem>
          <ListItem button>
            <a className={classes.listItemLink} href={URI_FEATURES}>
              <ListItemIcon>
                <FeatureIcon  className={classes.listItemIcon} />
              </ListItemIcon>
              <ListItemText primary="Features" />
            </a>
          </ListItem>
        </List>
      </div>
    );

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            {pageBackUrl ? (
              <IconButton
                color="inherit"
                aria-label="Back"
                onClick={() => {
                  this.handleBack(pageBackUrl);
                }}
              >
                <ArrowBack />
              </IconButton>
            ) : null}

            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.grow}
            >
              {pageTitle}
            </Typography>
            <UserPropertyMenu />
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <Container maxWidth="lg" className={classes.container}>
            <div className={classes.toolbar} />
            {this.props.children}
          </Container>
        </main>
      </div>
    );
  }
}

BoostMainLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default withRoot(
  withStyles(styles, { withTheme: true })(BoostMainLayout)
);
