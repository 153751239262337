import React from "react";
import MainLayout from "../../components/MainLayout";
import BoostFeaturesListItems from "./ListItems";

class BoostFeaturesList extends React.Component {
  render() {
    return (
      <div>
        <MainLayout pageTitle="Features">
          <BoostFeaturesListItems />
        </MainLayout>
      </div>
    );
  }
}

export default BoostFeaturesList;
