import React from "react";
import ReactDOM from "react-dom";
import Overview from "./pages/overview";
import BoostFeaturesList from "./pages/ELP";
import BoostFeaturesDetails from "./pages/EDP";
import * as serviceWorker from "./serviceWorker";

const getUrlParameter = sParam => {
  let sPageURL = decodeURIComponent(window.location.search.substring(1));
  let sURLVariables = sPageURL.split("&");

  for (let i = 0; i < sURLVariables.length; i++) {
    let sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? undefined : sParameterName[1];
    }
  }
};
const $loginForm = document.querySelector(".login-form");
if ($loginForm) {
  const action = $loginForm.action;
  const redir = getUrlParameter("redir");
  if (redir !== undefined) {
    $loginForm.action = action + "?redir=" + redir;
  }
}

if (document.getElementById("boost-overview")) {
  ReactDOM.render(<Overview />, document.getElementById("boost-overview"));
}

if (document.getElementById("boost-features-list")) {
  ReactDOM.render(
    <BoostFeaturesList />,
    document.getElementById("boost-features-list")
  );
}

if (document.getElementById("boost-features-details")) {
  ReactDOM.render(
    <BoostFeaturesDetails isDraft={false} />,
    document.getElementById("boost-features-details")
  );
}

if (document.getElementById("boost-features-draft")) {
  ReactDOM.render(
    <BoostFeaturesDetails isDraft={true} />,
    document.getElementById("boost-features-draft")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
