import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  MetricsContainer,
  Metrics,
  Metric,
  SubTitle,
  FeaturesSummary
} from "../atom";
import Summary from "./Summary";
import Timeline from "./Timeline";
import {accounting} from "accounting";


class featureDetailsOverview extends React.Component {
  render() {
    if (!this.props.data) {
      return null;
    }
    const eDatas = this.props.data;
    const timeline = this.props.timeline;

    return (
      <div>
        <Grid container spacing={2}>
          {FeaturesSummary.map(data => (
            <Grid item xs={4} key={data.Key}>
              <MetricsContainer elevation={1}>
                <Metrics>
                  <Metric>
                    <h3>{data.Title}</h3>
                    <span>{accounting.formatNumber(eDatas[data.Key]) || 0}</span>
                    <SubTitle>
                      <strong>{ accounting.formatNumber(eDatas[data.SubKey])|| 0} </strong>
                      {data.SubTitle}
                    </SubTitle>
                  </Metric>
                </Metrics>
              </MetricsContainer>
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MetricsContainer elevation={1}>
              <h3>Test summary</h3>
              <Summary goals={eDatas.goal} />
            </MetricsContainer>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MetricsContainer elevation={1}>
              <h3>Feature timeline</h3>
              <Timeline timeline={timeline} />
            </MetricsContainer>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default featureDetailsOverview;
