import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingBtnWrapper = styled.div`
  position: relative;
  display: inline-block;

  [role="progressbar"] {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -12px;
  }
`;
class LoadingBtn extends React.Component {
  render() {
    const {
      variant,
      color,
      disabled,
      plainDisabled,
      onClick,
      children,
      type,
      size
    } = this.props;
    return (
      <LoadingBtnWrapper>
        <Button
          variant={variant}
          color={color}
          disabled={plainDisabled || disabled}
          onClick={onClick}
          size={size}
          type={type}
        >
          {children}
        </Button>
        {plainDisabled ? null : disabled && <CircularProgress size={24} />}
      </LoadingBtnWrapper>
    );
  }
}
export default LoadingBtn;
