function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

function createData(
  feature_id,
  name,
  variants_percent,
  visitors_exposed,
  conversion,
  conversion_rate,
  revenue,
  last_updated_at
) {
  return {
    feature_id,
    name,
    variants_percent,
    visitors_exposed,
    conversion,
    conversion_rate,
    revenue,
    last_updated_at
  };
}

let featuresStatus = {
  live: { count: 0 },
  // scheduled: { count: 0 },
  paused: { count: 0 },
  drafts: { count: 0 },
  archived: { count: 0 },
  allItems: 0
};
const featuresStatusArr = [
  "live",
  // "scheduled",
  "paused",
  "drafts",
  "archived"
];

function getTableRowsData(FeaturesDatas, status, notFirstTime) {
  let tableRows = [];
  let filterStatus = status || "live";

  FeaturesDatas.forEach(item => {
    const isDraft = item.draft_version;
    const isLive = item.state === "LIVE";
    const isPaused = item.state === "PAUSED";
    const isArchived = item.state === "ARCHIVED";
    if (!notFirstTime) {
      if (isLive) {
        featuresStatus["live"].count++;
      }

      if (isPaused) {
        featuresStatus["paused"].count++;
      }

      // count drafts item
      if (isDraft) {
        featuresStatus["drafts"].count++;
      }

      if (isArchived) {
        featuresStatus["archived"].count++;
      }

      featuresStatus.allItems++;
    }

    if (
      (filterStatus === "drafts" && isDraft) ||
      (filterStatus === "live" && isLive) ||
      (filterStatus === "paused" && isPaused) ||
      (filterStatus === "archived" && isArchived)
    ) {
      tableRows.push(
        createData(
          item.feature_id,
          item.feature_name,
          item.variants_percent,
          item.total_visitors,
          item.total_converters,
          item.conversion_rate,
          item.revenue,
          new Date(item.last_updated_at).toDateString()
        )
      );
    }
  });
  return tableRows;
}

export {
  stableSort,
  getSorting,
  getTableRowsData,
  featuresStatus,
  featuresStatusArr
};
