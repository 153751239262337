import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export default function ConfirmModal({ open: _open, onConfirm, onClose }) {
  const [open, setOpen] = useState(_open || false);

  useEffect(() => {
    setOpen(_open);
  }, [_open]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Delete feature?</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure to delete it?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            onConfirm();
            onClose();
          }}
          color="primary"
        >
          yes
        </Button>
        <Button onClick={onClose} color="primary" autoFocus>
          no
        </Button>
      </DialogActions>
    </Dialog>
  );
}
