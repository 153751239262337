const BOOST__DOMAINS =
  window.__BOOST__DOMAINS && window.__BOOST__DOMAINS.domains
    ? window.__BOOST__DOMAINS.domains
    : "";
const BOOST__DOMAINID = window.__BOOST__DOMAINID || null;
const BOOST__FEATUREID = window.__BOOST__FEATUREID;
const API_PREFIT = `/api/stat/d/${BOOST__DOMAINID}`;
const API_FEATURES_DETAILS = `${API_PREFIT}/features/${BOOST__FEATUREID}`;
const API_FEATURES_DETAILS_v2 = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}`;
const API_FEATURES_UNPAUSE = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}/unpause`;
const API_FEATURES_PAUSE = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}/pause`;
const API_FEATURES_UNARCHIVE = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}/unarchive`;
const API_FEATURES_ARCHIVE = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}/archive`;
const API_FEATURES_RELEASE = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}/release`;
const API_FEATURES_DELETE = `/api/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}`;
const API_FEATURES_LISTS = `${API_PREFIT}/features`;
const API_OVERVIEW = `${API_PREFIT}/overview`;
const API_STREAM = `${API_PREFIT}/overview/stream`;
const API_FEATURES_EXPROT = `/api/export/stat/d/${BOOST__DOMAINID}/features/${BOOST__FEATUREID}/series`;

const BOOST__USER = window.__BOOST__USER;
let BOOST__DOMAIN, BOOST__PERMISSIONS;
let domains = [];

if (BOOST__DOMAINS && BOOST__DOMAINS.length) {
  for (let i = 0; i < BOOST__DOMAINS.length; i++) {
    let obj = BOOST__DOMAINS[i];
    if (obj.id === BOOST__DOMAINID) {
      BOOST__DOMAIN = obj;
      BOOST__PERMISSIONS = obj.permissions;
    } else {
      domains.push(obj);
    }
  }
}

export {
  BOOST__PERMISSIONS,
  BOOST__USER,
  BOOST__DOMAIN,
  BOOST__DOMAINID,
  BOOST__FEATUREID,
  domains,
  API_FEATURES_DETAILS,
  API_FEATURES_DETAILS_v2,
  API_FEATURES_LISTS,
  API_OVERVIEW,
  API_STREAM,
  API_FEATURES_UNPAUSE,
  API_FEATURES_PAUSE,
  API_FEATURES_UNARCHIVE,
  API_FEATURES_ARCHIVE,
  API_FEATURES_RELEASE,
  API_FEATURES_DELETE,
  API_FEATURES_EXPROT
};
