import React from "react";
import MainLayout from "../../components/MainLayout";
import PerformanceTracker from "./PerformanceTracker";
import LiveStream from "./LiveStream";

class BoostOverview extends React.Component {
  render() {
    return (
      <div>
        <MainLayout pageTitle="Overview">
          <PerformanceTracker />
          <LiveStream />
        </MainLayout>
      </div>
    );
  }
}

export default BoostOverview;
