import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import axios from "axios";
import {accounting} from "accounting";

import Loading from "../../components/Loading";

import {
  stableSort,
  getSorting,
  getTableRowsData,
  featuresStatus,
  featuresStatusArr
} from "./TableSorting";
import EnhancedTableHead from "./TableHead";

import {
  BOOST__PERMISSIONS,
  BOOST__DOMAINID,
  API_FEATURES_LISTS,
  BOOST__DOMAIN
} from "../../Utils";


import SimpleModalWrapped from "../../components/FeatureFromModal";

const FeaturesContainer = styled(Paper)`
  padding: 0;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1) !important;
  thead th {
    text-align: left;
    padding-left: 24px;
  }
  tbody tr {
    cursor: pointer;
    &:hover {
      background: #f9fafb;
    }
  }
`;
const FeaturesTabs = styled.div`
  margin: -24px -24px 24px;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  button {
    min-width: 75px;
  }
`;

const NoFeatureTips = styled.div`
  padding: 64px;
  text-align: center;
`;

const CenterLoading = styled.div`
  > div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-left: 120px;
  }
`;

class BoostFeaturesListItems extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      data: null,
      order: "asc",
      orderBy: "name",
      page: 0,
      statusValue: 0,
      status: featuresStatus
    };

    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(feature_id, statusValue) {
    const statusName = featuresStatusArr[statusValue];
    const detailsPageURI = `/d/${BOOST__DOMAINID}/features/${feature_id}${
      statusName === "drafts" ? "/draft" : ""
    }`;
    window.location.assign(detailsPageURI);
  }

  handleRequestSort(event, domain) {
    const isDesc =
      this.state.orderBy === domain && this.state.order === "desc";
    this.setState({
      order: isDesc ? "asc" : "desc",
      orderBy: domain
    });
  }

  handleChangePage(event, newPage) {
    this.setState({
      page: newPage
    });
  }

  handleChangeStatus(event, newValue) {
    const statusName = featuresStatusArr[newValue];

    this.setState({
      statusValue: newValue,
      rows: getTableRowsData(this.FeaturesDatas, statusName, true)
    });
  }

  async componentWillMount() {
    try {
      const result = await axios.get(API_FEATURES_LISTS);
      const FeaturesDatas = result.data.data.all;
      this.FeaturesDatas = FeaturesDatas;

      this.setState({
        data: FeaturesDatas
      });

      if (FeaturesDatas.length) {
        this.setState({
          rows: getTableRowsData(FeaturesDatas)
        });
      }
    } catch (error) {
      this.setState({
        error
      });
    }
  }

  render() {
    let { rows, order, orderBy, page, statusValue, status, data } = this.state;
    const rowsPerPage = 15;
    return (
      <div>
        {this.state.error ? console.error(this.state.error) : null}
        {!data ? (
          <CenterLoading>
            <Loading />
          </CenterLoading>
        ) : status.allItems ? (
          <div>
            <FeaturesTabs>
              <Tabs
                value={statusValue}
                indicatorColor="primary"
                onChange={this.handleChangeStatus}
              >
                {Object.keys(status).map(
                  key =>
                    status[key].count !== undefined && (
                      <Tab
                        disableRipple
                        label={`${key} (${status[key].count})`}
                        key={key}
                        disabled={status[key].count ? false : true}
                      />
                    )
                )}
              </Tabs>
            </FeaturesTabs>

            {rows.length ? (
              <FeaturesContainer>
                <Table aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {stableSort(rows, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(row => {
                        return (
                          <TableRow
                            key={row.feature_id}
                            onClick={this.handleClick.bind(
                              this,
                              row.feature_id,
                              statusValue
                            )}
                          >
                            <TableCell scope="row">{row.name}</TableCell>
                            <TableCell>{Math.round(row.variants_percent)}%</TableCell>
                            <TableCell>{accounting.formatNumber(row.visitors_exposed)}</TableCell>
                            <TableCell>{accounting.formatNumber(row.conversion)}</TableCell>
                            <TableCell>{Math.fround(row.conversion_rate*100).toFixed(2)}%</TableCell>
                            <TableCell>{BOOST__DOMAIN && BOOST__DOMAIN.currency_symbol ?accounting.formatMoney(row.revenue, BOOST__DOMAIN.currency_symbol):accounting.formatNumber(row.revenue)}</TableCell>
                            <TableCell>{row.last_updated_at}</TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
                {rows.length > rowsPerPage && (
                  <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={this.handleChangePage}
                  />
                )}
              </FeaturesContainer>
            ) : (
              <NoFeatureTips>
                <p>
                  You don’t have any{" "}
                  {featuresStatusArr[this.state.statusValue]} features yet
                </p>
              </NoFeatureTips>
            )}
            {BOOST__PERMISSIONS.edit && (
              <SimpleModalWrapped isInner={false} />
            )}
          </div>
        ) : (
          <NoFeatureTips>
            <p>You don't have any feature yet.</p>
            {BOOST__DOMAIN.permissions.edit && (
              <SimpleModalWrapped isInner={true} />
            )}
          </NoFeatureTips>
        )}
      </div>
    );
  }
}

export default BoostFeaturesListItems;
