import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import _DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import styled from "styled-components";

const DialogContent = styled(_DialogContent)`
  min-width: 600px;
`;

const ReleaseNoteDialog = ({ open, handleClose, historyText }) => {
  const [text, setText] = useState(historyText || undefined);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(undefined);
      }}
      aria-labelledby="releaseNote"
    >
      <DialogTitle id="releaseNote">Release log</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="releaseNote"
          label="release note"
          placeholder="Enter a release note for this release"
          defaultValue={text}
          type="string"
          multiline={true}
          rowsMax={4}
          fullWidth
          onChange={e => {
            setText(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose(undefined);
          }}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleClose(text);
          }}
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReleaseNoteDialog;
