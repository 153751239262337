import React from "react";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function Summary(props) {
  const { goals } = props;
  return (
    <Table className="goal-list">
      <TableHead>
        <TableRow>
          <TableCell width="60%">GOAL NAME</TableCell>
          <TableCell align="right">SAMPLE SIZE</TableCell>
          <TableCell align="right">UPLIFT</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {goals &&
          goals.map(goal => {
            return goal.name ? (
              <TableRow key={goal.id}>
                <TableCell component="th" scope="row">
                  {goal.name}
                </TableCell>
                <TableCell align="right">{goal.completeness}</TableCell>
                <TableCell align="right">{goal.uplift}</TableCell>
              </TableRow>
            ) : null;
          })}
      </TableBody>
    </Table>
  );
}

export default Summary;
