const getPublishVersion = ({
  draft_version,
  state,
  latest_published_version,
  waiting_publish_version,
  isDraftPage = false
}) => {
  let publishVerison;
  if (isDraftPage && draft_version) {
    publishVerison = draft_version;
  } else {
    // state will be 0, so need to tweak logic
    if (typeof state === "number") {
      if (state === 0) {
        // paused, show waiting_publish_version
        publishVerison = waiting_publish_version;
      } else {
        if (latest_published_version) {
          publishVerison = latest_published_version;
        } else if (waiting_publish_version) {
          publishVerison = waiting_publish_version;
        }
      }
    }
  }

  return publishVerison;
};

export { getPublishVersion };
