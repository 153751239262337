import React from "react";
import CalcDate from "./CalcDate";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const TimelineContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  flex: auto;
  padding: 24px 0;
  .subtitle1 {
    font-size: 14px;
    font-weight: 400;
  }
  .subtitle2 {
    font-size: 12px;
    font-weight: 400;
  }
  .timeline-bar {
    box-sizing: border-box;
    margin-right: 4px;
    margin-top: 8px;
    font-size: 14px;
    padding: 0 1px;
    height: 24px;
    line-height: 24px;
    overflow: hidden;
    cursor: pointer;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    &.timeline-paused {
      background-color: #cccfd3;
    }
  }
`;

const TimelineTooltip = styled.div`
  font-size: 12px;
  line-height: 1.4;
  h5 {
    margin: 0 0 2px;
    font-size: 16px;
    & + div {
      margin-bottom: 12px;
    }
  }
  p {
    margin: 0;
  }
`;

const style = theme => ({
  timelinebar: {
    background: theme.palette.primary.main
  }
});

function Timeline(props) {
  const { classes, timeline } = props;
  const { totalDays, dates } = CalcDate(timeline);

  return (
    <TimelineContainer>
      {dates.map(date => {
        return (
          <div
            style={{ width: `${(date.days / totalDays) * 100}%` }}
            key={date.start_at}
          >
            {date.days / totalDays > 0.17 && (
              <div>
                <Typography variant="subtitle1" className="subtitle1">
                  {date.name}
                </Typography>
                <Typography
                  variant="subtitle2"
                  className="subtitle2"
                  color="textSecondary"
                >
                  {date.start_at.substr(0, 10)} ~{" "}
                  {(date.end_at && date.end_at.substr(0, 10)) || "now"}
                </Typography>
              </div>
            )}
            <Tooltip
              classes={{ tooltip: "timelineTooltip" }}
              title={
                <TimelineTooltip>
                  <h5>{date.name || "unknown"}</h5>
                  <div>
                    {date.start_at.substr(0, 10)} ---{" "}
                    {(date.end_at && date.end_at.substr(0, 10)) || "now"}
                  </div>
                  <div>
                    <p>
                      {date.type === 0
                        ? "Published"
                        : date.name
                        ? date.name
                        : "unknown"}{" "}
                      by {date.operated_by.email}
                    </p>
                    <p>on {new Date(date.start_at).toDateString()}</p>
                    {date.release_note && <p>note:{date.release_note}</p>}
                  </div>
                </TimelineTooltip>
              }
              placement="top"
            >
              <div
                className={
                  date.type
                    ? "timeline-bar timeline-paused"
                    : `timeline-bar ${classes.timelinebar}`
                }
              >
                {date.days} days
              </div>
            </Tooltip>
          </div>
        );
      })}
    </TimelineContainer>
  );
}

export default withStyles(style, { withTheme: true })(Timeline);
